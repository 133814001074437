body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.teamName {
  font-size: calc(5px + 1.5vmin)
  /* font-size: 50px; */
}

.teamDate {
  font-size: calc(5px + 2vmin)
  /* font-size: 50px; */
}

.tableHeader {
  font-size: calc(5px + 2.5vmin)
}

th {
  font-size: 1em;
}

@media only screen and (max-device-width: 480px) {
  .MuiContainer-root {
      padding-left: 0px !important;
      padding-right: 0px !important;
  }
  .teamName {
    font-size: calc(4px + 1vmin)
  }
  .teamDate {
    font-size: calc(4px + 1vmin)
  }
  .MuiBox-root {
    height: 20px !important;
  }
  .MuiTableCell-head {
    font-size: calc(4px + 1vmin) !important;
  }
  .MuiSvgIcon-root {
    font-size: calc(4px + 2vmin) !important;
  }
}